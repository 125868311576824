export const log = (debugVar, desc = false, type = false) => {
    if(process.env.NODE_ENV !== "production1" && process.env.REACT_APP_DEBUG_LOG === "true"){
        if(desc){
            let color = 'white';
            let bgc = 'black';
            let icon = "📌";
            switch (type) {
                case "success":  color = "#FFF";      bgc = "LimeGreen";    icon="✅";       break;
                case "info":     color = "#FFF";      bgc = "Turquoise";    icon="❕";      break;
                case "error":    color = "Red";        bgc = "Black";           break;
                case "start":    color = "OliveDrab";  bgc = "PaleGreen";       break;
                case "warning":  color = "Tomato";     bgc = "Black";           break;
                case "end":      color = "Orchid";     bgc = "MediumVioletRed"; break;
                default: color = 'white';
            }
            console.log(icon+" %c" + desc, "color:" + color + ";font-weight:bold; background-color: " + bgc + ";", debugVar);
        }else{
            console.log(debugVar);
        }
    }
}

export const randomIdentifier = () => {
    let id = (Math.random()*1000).toString() + ((new Date()).getTime()).toString()
    return id
}