import React, { useEffect, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, updateInfo } from "../store/userSlice";
import { updateDashboard } from "../store/dashboardSlide";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "axios";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { selectInfoDashboard } from "../store/dashboardSlide";

const initialState = { count: 0 };
function reducer(state, action) {
  switch (action.type) {
    case 'increment':
      return { count: state.count + 1 };
    case 'decrement':
      return { count: state.count - 1 };
    default:
      throw new Error();
  }
}

export default function Home() {
    // check useReducer
    const [state, dispatch2] = useReducer(reducer, initialState);

    const API_URL = process.env.REACT_APP_API_URL;
    // const API_URL = "https://localhost:3000";
    const IMG_URL_CDN = "https://steamcdn-a.akamaihd.net";
    const history = useHistory();
    const dispatch = useDispatch();

    const [username, setUsername] = useState("dev001");
    const [info, setInfo] = useState({
        appid: "",
        name: "",
        image_url:`${IMG_URL_CDN}/steam/apps/1238810/header.jpg`,
        type: "Openworld",
        version: "1.2",
        required_age: "1",
        publisher: "",
        developer: "",
    });
    const [gameDetail, setGameDetail] = useState({});
    const [steamList, setSteamList] = useState([1]);
    // console.log(steamList, 'init arr steam'); //168220

    var stateDash = useSelector(selectInfoDashboard);
    useEffect(() => {
        //check state dashboardSlide
        // console.log(info, "info1");
    }, [info]);

    useEffect(() => {
        axios
            .get(
                "https://api.steampowered.com/ISteamApps/GetAppList/v0001/?format=json"
            )
            .then(function (res) {
                // v0001
                let list = res?.data.applist.apps.app || [];

                // //v0002
                // let list = res?.data?.applist?.apps || [];

                // list = list.slice(0, 90000);
                // list = list.slice(0, 10000);
                list = list.map((item) => {
                    return {
                        ...item,
                        id: item.appid,
                    };
                });
                // console.log(list, "listtt");

                setSteamList(list);
                // dispatch(updateInfos(infos));
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
    // console.log(steamList, 'steamListsteamList');

    // chunkArrayInGroups
    function chunkArrayInGroups(arr, size) {
        var myArray = [];
        for (var i = 0; i < arr.length; i += size) {
            myArray.push(arr.slice(i, i + size));
        }
        return myArray;
    }
    // console.log(chunkArrayInGroups(["a", "b", "c", "d"], 2));

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results, "handleOnSearch");
    };

    const handleOnHover = (result) => {
        // // the item hovered
        // console.log(result)
    };

    const handleOnSelect = (item) => {
        // the item selected
        console.log(item, "handleOnSelect_item");
        let image_url = `${IMG_URL_CDN}/steam/apps/${item.appid}/header.jpg`;
        let { appid, name } = item;

        // setInfo((prevState) => {
        //     console.log(prevState, "preSstaet");
        //     return {
        //         ...prevState,
        //         image_url,
        //         appid,
        //         name,
        //     };
        // });
        // setInfo(item)

        axios
            .get(
                "https://store.steampowered.com/api/appdetails?appids=" + appid
            )
            .then(function (res) {
                let data = res?.data?.[appid]?.data;
                let { required_age, price_overview } = data;
                let publisher = data?.publishers?.[0] || '-';
                let developer = data?.developers?.[0] || '-';
                // debugger;

                setGameDetail(data);
                setInfo((prevState) => {
                    return {
                        ...prevState,
                        appid,             
                        name,             
                        required_age,             
                        price: price_overview?.final_formatted || "1đ",
                        publisher,
                        developer,
                    };
                });

                // dispatch(updateInfos(infos));
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleOnFocus = () => {
        console.log("Focused");
    };

    const formatResult = (item) => {
        let image_url = `${IMG_URL_CDN}/steam/apps/2172950/header.jpg`;
        // console.log(item, "format_resutl");
        // return item;
        return (
            <p
                dangerouslySetInnerHTML={{ __html: `<strong>${item.name}</strong>` }}
            />
            // <p dangerouslySetInnerHTML={{__html: `<strong>${item.name}  <img className="img-fluid img-thumbnail img-search" style={{maxWidth:'200px'}}  src=${image_url} /> </strong>`}}></p>
        );
    };
    // useEffect(() => console.log(username, 11111), [username]);

    const handleChangeInfo = (e) => {
        const { name, value } = e.target;
        debugger
        setInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        console.log(info, "infoinfo_handleChangeInfo");
    };
    useEffect(() => {
        console.log(info, "infoinfo_useEffect2");
    }, [info]);

    const handleUpdateUser = () => {
        dispatch(updateUser(username));
        history.push("/dashboard");
    };

    const handleUpdateInfo = (e) => {
        e.preventDefault();
        console.log(info, "infooo");
        dispatch(updateInfo(info));

        axios
            .post(API_URL + "/infos", info)
            .then(function (response) {
                console.log(response, "res2000");
                history.push("/dashboard");
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const fuseOptions = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 5,
        keys: [
            "name",
        ]
    }

    const gotoInfo = () => {
        history.push("/dashboard");
    };

    const gotoDash = (e) => {
        e.preventDefault();
        dispatch(updateDashboard("new_dashboard"));
        history.push("/dashboard");
    };

    return (
        <>
            <div className="container pb-4">
                <h5>
                    Checker toolkit:
                    <span> Home</span>
                    {/* <span> { username }</span> */}
                </h5>

                <div>
                    useReducer Count: {state.count}
                    <button className="btn btn-sm btn-outline-primary me-2 ms-3" onClick={() => dispatch2({ type: 'increment' })}>+</button>
                    <button className="btn btn-sm btn-outline-warning me-2" onClick={() => dispatch2({ type: 'decrement' })}>-</button>
                </div>

                <form className="">
                    <div>
                        <label htmlFor="" className="form-label">
                            Guest name
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="user"
                            value={username}
                            onChange={(event) => {
                                setUsername(event.target.value);
                                // console.log(event.target.value, 8888);
                            }}
                        />
                    </div>
                    <button
                        className="btn btn-primary d-block mt-2"
                        onClick={() => handleUpdateUser()}
                    >
                        Update guest
                    </button>
                </form>

                <h6 className="mt-3">Update item</h6>
                <form className="">
                    <div className="mt-1">
                        <label htmlFor="" className="form-label">
                            Type
                        </label>
                        <select
                            name="type"
                            className="form-control"
                            type="text"
                            placeholder="Type"
                            value={info?.type || ""}
                            onChange={handleChangeInfo}
                        >
                            {/* <option value="RPG">pls select</option> */}
                            <option value="RPG">RPG</option>
                            <option value="Openworld">Openworld</option>
                            <option value="Horror">Horror</option>
                        </select>
                    </div>
                    <div className="box_autocomplete mt-1">
                        <label htmlFor="" className="form-label">
                            Name
                        </label>
                        {/* <input
                            name="name"
                            className="form-control"
                            type="text"
                            placeholder="name"
                            value={info?.name || ''}
                            onChange={handleChangeInfo}
                        />
                         */}
                        <ReactSearchAutocomplete
                            items={steamList}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            autoFocus
                            formatResult={formatResult}
                            fuseOptions={fuseOptions}
                        />
                    </div>
                    <div className="mt-2">
                        <label htmlFor="" className="form-label">
                            Price
                        </label>
                        <input
                            readOnly
                            name="price"
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={info?.price || 0}
                            onChange={(e) => handleChangeInfo(e)}
                        />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="" className="form-label">
                            Required age
                        </label>
                        <input
                            readOnly
                            name="required_age"
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={info?.required_age || 0}
                            onChange={(e) => handleChangeInfo(e)}
                        />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="" className="form-label">
                            Version
                        </label>
                        <input
                            name="version"
                            className="form-control"
                            type="text"
                            placeholder="Version"
                            value={info?.version || ""}
                            onChange={(e) => handleChangeInfo(e)}
                        />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="" className="form-label">
                            Publisher
                        </label>
                        <input
                            name="publisher"
                            className="form-control"
                            type="text"
                            placeholder="Publisher"
                            value={info?.publisher || ""}
                            onChange={(e) => handleChangeInfo(e)}
                        />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="" className="form-label">
                            Developer
                        </label>
                        <input
                            name="developer"
                            className="form-control"
                            type="text"
                            placeholder="Developer"
                            value={info?.developer || ""}
                            onChange={(e) => handleChangeInfo(e)}
                        />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="" className="form-label">
                            Link
                        </label>
                        <input
                            name="link"
                            className="form-control"
                            type="text"
                            placeholder="Link"
                            value={
                                info?.image_url ||
                                "https://i.imgur.com/2XBqLsa.jpg"
                            }
                            onChange={(e) => handleChangeInfo(e)}
                        />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="" className="form-label me-2">
                            Image
                        </label>
                        <img
                            className="img-fluid img-thumbnail"
                            style={{ maxWidth: "200px" }}
                            src={info?.image_url}
                        />
                    </div>

                    {gameDetail?.screenshots?.length > 0 && (
                        <div className="card mt-2">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item p-0 mt-1 text-center">
                                    {gameDetail?.screenshots.map(
                                        (item, index) => {
                                            // console.log(
                                            //     item,
                                            //     "item_screenshot"
                                            // );
                                            return (
                                                <img
                                                    key={index}
                                                    className="img-fluid img-thumbnail my-2 mx-2 img_screenshot"
                                                    src={item?.path_thumbnail}
                                                />
                                            );
                                        }
                                    )}
                                </li>
                            </ul>
                        </div>
                    )}

                    <button
                        className="btn btn-primary me-2 mt-3"
                        onClick={(e) => handleUpdateInfo(e)}
                    >
                        Update info
                    </button>
                    <button
                        className="btn btn-info me-2 mt-3"
                        onClick={(e) => gotoDash(e)}
                    >
                        Goto dash
                    </button>
                    {/* <button className="btn btn-sm btn-info me-2 mt-2" onClick={() => gotoInfo()}>goto info</button> */}
                </form>
            </div>
        </>
    );
}
