import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import Dashboard from "./pages/Dashboard.js";
import Home from "./pages/Home.js";
import { log } from './utils/helpers';

export default function App() {
    log(process.env.NODE_ENV, 'NODE_ENV', "success");
    log(process.env.REACT_APP_API_URL, 'REACT_APP_API_URL', "success");

    return (
        <Router>
            <Switch>
                <Route path="/home">
                    <Home />
                </Route>

                <Route path="/dashboard">
                    <Dashboard />
                </Route>

                <Redirect to="/home" />
            </Switch>
        </Router>
    );
}
